// extracted by mini-css-extract-plugin
export var contentWrapper = "Hero-module--contentWrapper--2ONzS";
export var description = "Hero-module--description--1AAIK";
export var desktopBackground = "Hero-module--desktopBackground--3cqdg";
export var form = "Hero-module--form--1VOga";
export var heading = "Hero-module--heading--1CQMs";
export var imageMobile = "Hero-module--imageMobile--3G-7e";
export var list = "Hero-module--list--lPoMq";
export var mobileButton = "Hero-module--mobileButton--2uwsf";
export var nextSectionTopbar = "Hero-module--nextSectionTopbar--1fow-";
export var rating = "Hero-module--rating--mtdJP";
export var ratingContainer = "Hero-module--ratingContainer--1N3WW";
export var ratingDescription = "Hero-module--ratingDescription--18aHH";
export var ratingDescriptionWrapper = "Hero-module--ratingDescriptionWrapper--1A32x";
export var ratingGoogleLogo = "Hero-module--ratingGoogleLogo--2wZff";
export var ratingStars = "Hero-module--ratingStars--1yUzE";
export var ratingWrapper = "Hero-module--ratingWrapper--3qXnc";
export var self = "Hero-module--self--kpvr5";
export var title = "Hero-module--title--hfYB1";
export var withoutForm = "Hero-module--withoutForm--2r8hf";