import React from "react";

import * as styles from "./AdvertSection.module.scss";
import Image from "../../components/Image";
import Button from "../../components/Button";
import clsx from "../../utils/clsx";
import { parseMardown } from "../../utils/textTransformations";

const Item = ({ text, icon }) => {
  return (
    <li className={styles.item} key={text}>
      <span className={styles.iconWrapper}>
        {icon ? (
          <Image className={styles.icon} image={icon} />
        ) : (
          <span className={styles.tick}>✓</span>
        )}
      </span>

      {parseMardown(text)}
    </li>
  );
};

const AdvertSection = ({
  data: { title, description, ctaText, reversed, list, image, greyBackground },
}) => {
  return (
    <div
      className={clsx(
        styles.self,
        reversed && styles.reversed,
        greyBackground && styles.greyBackground
      )}
    >
      <div className={styles.inner}>
        <div className={styles.content}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {description && (
            <p className={styles.description}>{parseMardown(description)}</p>
          )}
          {!!list.length && <ul className={styles.list}>{list.map(Item)}</ul>}
          {ctaText && (
            <Button href="#formularz-kontaktowy" className={styles.button}>
              {ctaText}
            </Button>
          )}
        </div>
        {image && (
          <div className={styles.imageWrapper}>
            <Image className={styles.image} image={image} type="small" />
          </div>
        )}
      </div>
    </div>
  );
};

AdvertSection.displayName = "sections.advert-section";

export default AdvertSection;
