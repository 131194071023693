export const STATUS = {
  READY: "ready",
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

export function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };

  if (gtag) {
    gtag("event", "conversion", {
      send_to: "AW-310956792/sXzgCPyG6_kCEPilo5QB",
      event_callback: callback,
    });
  }
  return false;
}

export const cleanPhoneNumber = (number) => {
  return number.replace(/[\s-+]/g, "");
};

export const getTimestampFormatted = () => {
  // 27.10.2022, 13:40:46
  const d = new Date();
  const dateFormatted = `${d.getDate()}.${
    d.getMonth() + 1
  }.${d.getFullYear()}, ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;

  return dateFormatted;
};

export const getUtmValues = () => {
  const searchParams = new URLSearchParams(window?.document?.location?.search);

  return {
    utm_source: searchParams.get("utm_source") || "",
    utm_medium: searchParams.get("utm_medium") || "",
    utm_campaign: searchParams.get("utm_campaign") || "",
    utm_content: searchParams.get("utm_content") || "",
    utm_adname: searchParams.get("utm_adname") || "",
  };
};
