import React from "react";
import * as styles from "./DrawingCard.module.scss";
import { parseMardown } from "../../utils/textTransformations";

import Image from "../Image";

const DrawingCard = ({ card }) => {
  const { title, subtitle, icon, image } = card;

  let imageUrl = image?.formats?.small?.url || image.url;
  imageUrl = imageUrl.replace(/\.png$/, ".jpg");

  return (
    <div className={styles.self}>
      <div
        className={styles.card}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className={styles.transparentContainer}>
          <span className={styles.title}>{title}</span>
          <div className={styles.subtitle}>
            <div className={styles.bulletWrapper}>
              <Image className={styles.icon} image={icon} />
            </div>
            {parseMardown(subtitle)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawingCard;
