import React from "react";

import * as styles from "./ImageWithTextList.module.scss";
import Image from "../../components/Image";
import clsx from "../../utils/clsx";
import { parseMardown } from "../../utils/textTransformations";

const Item = (item) => {
  return (
    <div className={styles.item}>
      <Image className={styles.icon} image={item.image} convertToJpg />
      <p className={styles.text}>{parseMardown(item.title)}</p>
    </div>
  );
};

const ImageWithTextList = ({ data: { title, item: items } }) => {
  return (
    <div className={clsx(styles.self, items.length > 3 && styles.spread)}>
      <div className={styles.inner}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.items}>{items.map(Item)}</div>
      </div>
    </div>
  );
};

ImageWithTextList.displayName = "sections.image-with-text-list";

export default ImageWithTextList;
