import React from "react";

import clsx from "../../utils/clsx";
import * as styles from "./ImageTitleTextList.module.scss";
import Image from "../../components/Image";
import Button from "../../components/Button";
import { parseMardown } from "../../utils/textTransformations";

const Item = ({ title, text, image }) => {
  return (
    <div className={styles.item} key={text}>
      <Image className={styles.image} image={image} convertToWebp />
      <h3 className={styles.itemTitle}>{title}</h3>
      <p className={styles.itemText}>{parseMardown(text)}</p>
    </div>
  );
};

const ImageTitleTextList = ({
  data: { title, items, ctaText, greyBackground },
}) => {
  return (
    <div className={styles.self}>
      <div className={styles.inner}>
        <h2 className={styles.title}>{title}</h2>
        <div
          className={clsx(styles.itemsWrapper, greyBackground && styles.greyBg)}
        >
          <div className={styles.items}>{items.map(Item)}</div>
        </div>
        {ctaText && (
          <Button
            className={styles.button}
            href="#formularz-kontaktowy"
            color="blue"
          >
            {ctaText}
          </Button>
        )}
      </div>
    </div>
  );
};

ImageTitleTextList.displayName = "sections.image-title-text-list";

export default ImageTitleTextList;
