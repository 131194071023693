import React from "react";
import Slider from "react-slick";

import * as styles from "./SideCarousel.module.scss";
import "../../assets/css/slick.min.css";
import "../../assets/css/slick-theme.min.css";
import Arrow from "../../images/arrow.svg";

function SampleArrow(props) {
  const { onClick, direction } = props;
  return (
    <div
      className={`${styles.arrow} ${
        direction === "next" ? styles.arrowNext : styles.arrowPrev
      }`}
      onClick={onClick}
    >
      <img src={Arrow} alt="" />
    </div>
  );
}

const SideCarousel = ({ content, children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
  };

  const mobileSettings = {
    ...settings,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const tabletSettings = {
    ...settings,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleArrow direction="next" />,
    prevArrow: <SampleArrow direction="prev" />,
    draggable: false,
  };

  const desktopSettings = {
    ...settings,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleArrow direction="next" />,
    prevArrow: <SampleArrow direction="prev" />,
    draggable: false,
  };

  return (
    <div className={styles.self}>
      <div className={styles.onlyMobile}>
        <div className={styles.content}>{content}</div>
        <Slider {...mobileSettings}>{children}</Slider>
      </div>

      <div className={styles.tablet}>
        <div className={styles.content}>{content}</div>
        <div className={styles.carousel}>
          <Slider {...tabletSettings}>{children}</Slider>
        </div>
      </div>

      <div className={styles.desktop}>
        <div className={styles.desktopWrapper}>
          <div className={styles.content}>{content}</div>
          <div className={styles.carousel}>
            <Slider {...desktopSettings}>{children}</Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideCarousel;
