import React from "react";

import * as styles from "./Anchor.module.scss";

const Anchor = ({ data: { anchorId, padding } }) => {
  return (
    <div className={styles.self}>
      <div
        id={anchorId}
        className={styles.anchor}
        style={{
          marginTop: `${padding}px`,
        }}
      />
    </div>
  );
};

Anchor.displayName = "sections.anchor";

export default Anchor;
