import React, { useState } from "react";

import * as styles from "./FormShort.module.scss";
import Button from "../Button";
import clsx from "../../utils/clsx";
import Thumb from "../../images/thumb.svg";
import IdPointer from "../IdPointer";

import {
  STATUS,
  cleanPhoneNumber,
  getTimestampFormatted,
  getUtmValues,
} from "../../utils/formUtils";

import PolitykaPrywatnosci from "../../assets/pdfs/polityka-prywatnosci-edugo-v.1.1.pdf";
import { navigate } from "gatsby";

const FormShort = ({
  className,
  formId = "",
  id = "",
  buttonText,
  form: {
    spreadsheetId,
    ctaText,
    nameSurname,
    phone,
    agreement,
    success,
    error,
    thankYouPagePath,
  },
}) => {
  const onSubmit = (event) => {
    event.preventDefault();
    setStatus(STATUS.PENDING);

    const dateFormatted = getTimestampFormatted();

    const { elements } = event.target;

    const valuesToSend = {};
    const nameVal = elements?.name?.value;
    if (nameVal) {
      valuesToSend.imieNazwisko = nameVal;
    }
    const telVal = elements?.tel?.value;
    if (telVal) {
      valuesToSend.numerTel = cleanPhoneNumber(telVal);
    }

    const utmValues = getUtmValues();

    const domain =
      process.env.GATSBY_ROOT_URL === "http://localhost:8000"
        ? "http://localhost:8888"
        : "";

    fetch(`${domain}/.netlify/functions/processForm`, {
      method: "POST",
      body: JSON.stringify({
        czas: dateFormatted,
        strona: window?.location?.pathname,
        formularz: event.target.id,
        formID: spreadsheetId,
        ...valuesToSend,
        ...utmValues,
      }),
    })
      .then(() => {
        if (thankYouPagePath) {
          navigate(thankYouPagePath);
        }
        setStatus(STATUS.SUCCESS);
      })
      .catch(() => {
        setStatus(STATUS.ERROR);
      });
  };

  const [status, setStatus] = useState(STATUS.READY);

  const getId = (name) => `${formId}-${name}`;

  return (
    <>
      <IdPointer id={id} />
      <div className={clsx(styles.self, className)}>
        {(status === STATUS.READY || status === STATUS.PENDING) && (
          <>
            <form onSubmit={onSubmit} id={formId}>
              <div className={styles.fieldsWrapper}>
                {nameSurname.display !== false && (
                  <div>
                    <label className={styles.label} htmlFor={getId("name")}>
                      {nameSurname.text}
                    </label>
                    <input
                      id={getId("name")}
                      name="name"
                      type="text"
                      placeholder={nameSurname.placeholder}
                      required={nameSurname.required}
                      className={styles.input}
                    />
                  </div>
                )}

                {phone.display !== false && (
                  <div>
                    <label className={styles.label} htmlFor={getId("tel")}>
                      {phone.text}
                    </label>
                    <input
                      id={getId("tel")}
                      name="tel"
                      type="tel"
                      pattern="^[+0-9\s-]{9,18}$"
                      placeholder={phone.placeholder}
                      required={phone.required}
                      className={styles.input}
                    />
                  </div>
                )}
              </div>

              <div className={styles.checkboxWrapper}>
                <input
                  id={getId("agreement")}
                  name="agreement"
                  type="checkbox"
                  required={agreement.required}
                  className={styles.checkbox}
                />
                <label
                  htmlFor={getId("agreement")}
                  className={styles.checkboxText}
                >
                  {agreement.text}
                </label>
              </div>

              <p className={styles.info}>
                Klikając "{buttonText || ctaText}" oświadczasz, że zapoznałeś
                się z informacjami na temat przetwarzania danych osobowych,
                które znajdują się&nbsp;
                <a
                  className={styles.infoLink}
                  href={PolitykaPrywatnosci}
                  target="_blank"
                >
                  tutaj
                </a>
                .
              </p>

              <Button
                className={styles.button}
                id={getId("submit-button")}
                type="submit"
                disabled={status === STATUS.PENDING}
              >
                {buttonText || ctaText}
              </Button>
            </form>
          </>
        )}

        {status === STATUS.SUCCESS && (
          <div>
            <p className={styles.message}>{success}</p>
            <img id="img-success" className={styles.thumb} src={Thumb} />
          </div>
        )}
        {status === STATUS.ERROR && <p className={styles.message}>{error}</p>}
      </div>
    </>
  );
};

export default FormShort;
