import React from "react";

import * as styles from "./CoursesList.module.scss";
import Button from "../../components/Button";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../../components/Image";
import IdPointer from "../../components/IdPointer";

const Course = ({ data, ctaText }) => {
  if (data.node.notVisibleInCourses) {
    return null;
  }

  const { title } = data.node;
  const features = data.node.sections.filter(
    (section) => section.strapi_component === "sections.hero"
  )[0].features;
  const imageWithTextData = data.node.sections.filter(
    (section) => section.strapi_component === "sections.image-with-text"
  )[0];

  // const description = imageWithTextData.firstParagraph.replace(/\*\*/g, "");
  // const descriptionCut = `${description.slice(0, 100).replace(/\s$/, "")}...`;

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            image={imageWithTextData.image}
            type="small"
            convertToJpg
          />
          <div className={styles.label}>darmowa lekcja pokazowa</div>
        </div>
        <h3 className={styles.featureTitle}>{title}</h3>
        <ul className={styles.features}>
          {features.map((feature) => {
            return (
              <li className={styles.feature}>
                <span className={styles.tick}>✓</span>
                {feature.text}
              </li>
            );
          })}
        </ul>
        {/*<p className={styles.description}>{descriptionCut}</p>*/}
        <div className={styles.buttonWrapper}>
          <Button
            href={`/kurs/${data.node.slug}`}
            className={styles.button}
            color="outline"
          >
            {ctaText}
          </Button>
        </div>
      </div>
    </div>
  );
};

const CoursesList = ({ data: { title, ctaText } }) => {
  const { allStrapiLanding } = useStaticQuery(query);

  return (
    <>
      <IdPointer id="kursy" />
      <div className={styles.self}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.carousel}>
          {allStrapiLanding.edges.map((landing) => (
            <Course ctaText={ctaText} data={landing} />
          ))}
        </div>
      </div>
    </>
  );
};

CoursesList.displayName = "sections.courses-list";

export default CoursesList;

const query = graphql`
  query {
    allStrapiLanding {
      edges {
        node {
          menuOrder
          title
          slug
          strapiId
          sections
          notVisibleInCourses
        }
      }
    }
  }
`;
