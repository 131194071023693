import React from "react";

import * as styles from "./ShortFormSection.module.scss";
import FormShort from "../../components/FormShort";
import IdPointer from "../../components/IdPointer";
import Image from "../../components/Image";

const FormSection = ({
  data: { title, description, image, buttonText },
  form,
}) => {
  return (
    <>
      <IdPointer id="formularz-kontaktowy" />
      <div className={styles.self}>
        <div className={styles.inner}>
          {image && (
            <Image className={styles.image} image={image} convertToWebp />
          )}
          <div className={styles.content}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {description && <p className={styles.description}>{description}</p>}

            <FormShort
              className={styles.form}
              form={form}
              formId="short-form-section"
              buttonText={buttonText}
            />
          </div>
        </div>
      </div>
    </>
  );
};

FormSection.displayName = "sections.short-form-section";

export default FormSection;
