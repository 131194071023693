// extracted by mini-css-extract-plugin
export var badge = "CourseCard-module--badge--3CyoO";
export var badgeContainer = "CourseCard-module--badgeContainer--3Q6zK";
export var bottomText = "CourseCard-module--bottomText--3m3uZ";
export var bottomTextAdded = "CourseCard-module--bottomTextAdded--qtejC";
export var bulletWrapper = "CourseCard-module--bulletWrapper--jrhOT";
export var card = "CourseCard-module--card--AFRxW";
export var content = "CourseCard-module--content--3pPwE";
export var feature = "CourseCard-module--feature--2txGh";
export var header = "CourseCard-module--header--11RdB";
export var image = "CourseCard-module--image--18Tj5";
export var linkWrapper = "CourseCard-module--linkWrapper--GZzQV";
export var noPadding = "CourseCard-module--noPadding--2lxkT";
export var self = "CourseCard-module--self--siFNf";
export var textInCircle = "CourseCard-module--textInCircle--nHPIT";
export var title = "CourseCard-module--title--ueV8A";
export var transparentContainer = "CourseCard-module--transparentContainer--1J9xO";