import React, { useState } from "react";
import { navigate } from "gatsby";

import * as styles from "./Form.module.scss";
import Button from "../Button";
import clsx from "../../utils/clsx";
import Thumb from "../../images/thumb.svg";
import IdPointer from "../IdPointer";

import {
  STATUS,
  gtag_report_conversion,
  cleanPhoneNumber,
  getTimestampFormatted,
  getUtmValues,
} from "../../utils/formUtils";

import PolitykaPrywatnosci from "../../assets/pdfs/polityka-prywatnosci-edugo-v.1.1.pdf";

const Form = ({
  className,
  formId = "",
  id = "",
  form: {
    title,
    spreadsheetId,
    ctaText,
    nameSurname,
    phone,
    email,
    recommendationNameSurname,
    recommendationPhone,
    additionalInfo,
    discountCode,
    agreement,
    success,
    error,
    thankYouPagePath,
  },
}) => {
  // const onTest = () => {
  //   fetch("/.netlify/functions/test", {
  //     method: "POST",
  //     body: JSON.stringify({
  //       name: "onTest",
  //       email: "user1@gmail.com",
  //       phone: "0323339992",
  //       // formID: "1Oi4HlHim8XPEz7ckbgGDKq7smmWomYmaWtDmalxPC1Y",
  //       formID: spreadsheetId,
  //     }),
  //   });
  // };

  const onSubmit = (event) => {
    event.preventDefault();
    setStatus(STATUS.PENDING);

    const dateFormatted = getTimestampFormatted();

    const { elements } = event.target;

    const valuesToSend = {};
    const nameVal = elements?.name?.value;
    if (nameVal) {
      valuesToSend.imieNazwisko = nameVal;
    }
    const telVal = elements?.tel?.value;
    if (telVal) {
      valuesToSend.numerTel = cleanPhoneNumber(telVal);
    }
    const emailVal = elements?.email?.value;
    if (emailVal) {
      valuesToSend.email = emailVal;
    }
    const recommendationNameSurnameVal =
      elements?.recommendationNameSurname?.value;
    if (recommendationNameSurnameVal) {
      valuesToSend.rekomendacjaImieNazwisko = recommendationNameSurnameVal;
    }
    const recommendationPhoneVal = elements?.recommendationPhone?.value;
    if (recommendationPhoneVal) {
      valuesToSend.rekomendacjaTel = cleanPhoneNumber(recommendationPhoneVal);
    }
    const additionalInfoVal = elements?.additionalInfo?.value;
    if (additionalInfoVal) {
      valuesToSend.dodatkoweInformacje = additionalInfoVal;
    }
    const discountCodeVal = elements?.discountCode?.value;
    if (discountCodeVal) {
      valuesToSend.kodRabatowy = discountCodeVal;
    }

    const utmValues = getUtmValues();

    const domain =
      process.env.GATSBY_ROOT_URL === "http://localhost:8000"
        ? "http://localhost:8888"
        : "";

    fetch(`${domain}/.netlify/functions/processForm`, {
      method: "POST",
      body: JSON.stringify({
        czas: dateFormatted,
        strona: window?.location?.pathname,
        formularz: event.target.id,
        formID: spreadsheetId,
        ...valuesToSend,
        ...utmValues,
      }),
    })
      .then(() => {
        if (window?.location?.pathname === "/kurs/tworca-gier-komputerowych") {
          gtag_report_conversion();
        }

        if (thankYouPagePath) {
          navigate(thankYouPagePath);
        }

        setStatus(STATUS.SUCCESS);
      })
      .catch(() => {
        setStatus(STATUS.ERROR);
      });
  };

  const [status, setStatus] = useState(STATUS.READY);
  const [phoneNum, setPhoneNum] = useState("");

  const getId = (name) => `${formId}-${name}`;

  const handlePhoneChange = (event) => {
    const inputValue = event.target.value;
    const isValidPhone = /^[0-9]{9,18}$/.test(inputValue);
    setPhoneNum(inputValue);

    if (!isValidPhone) {
      event.target.setCustomValidity(
        'Wpisz poprawny numer nie używając znaków specjalnych, np. "+" oraz spacji'
      );
    } else {
      event.target.setCustomValidity("");
    }
  };

  return (
    <>
      <IdPointer id={id} />
      <div className={clsx(styles.self, className)}>
        {(status === STATUS.READY || status === STATUS.PENDING) && (
          <>
            <h2 className={styles.title}>{title}</h2>
            <form onSubmit={onSubmit} id={formId}>
              {nameSurname.display !== false && (
                <>
                  <label className={styles.label} htmlFor={getId("name")}>
                    {nameSurname.text}
                  </label>
                  <input
                    id={getId("name")}
                    name="name"
                    type="text"
                    placeholder={nameSurname.placeholder}
                    required={nameSurname.required}
                    className={styles.input}
                  />
                </>
              )}

              {phone.display !== false && (
                <>
                  <label className={styles.label} htmlFor={getId("tel")}>
                    {phone.text}
                  </label>
                  <input
                    id={getId("tel")}
                    name="tel"
                    type="tel"
                    value={phoneNum}
                    onChange={handlePhoneChange}
                    placeholder={phone.placeholder}
                    required={phone.required}
                    className={styles.input}
                  />
                </>
              )}

              {email.display !== false && (
                <>
                  <label className={styles.label} htmlFor={getId("email")}>
                    {email.text}
                  </label>
                  <input
                    id={getId("email")}
                    name="email"
                    type="email"
                    placeholder={email.placeholder}
                    required={email.required}
                    className={styles.input}
                  />
                </>
              )}

              {recommendationNameSurname?.display && (
                <>
                  <label
                    className={styles.label}
                    htmlFor={getId("recommendationNameSurname")}
                  >
                    {recommendationNameSurname.text}
                  </label>
                  <input
                    id={getId("recommendationNameSurname")}
                    name="recommendationNameSurname"
                    type="text"
                    placeholder={recommendationNameSurname.placeholder}
                    required={recommendationNameSurname.required}
                    className={styles.input}
                  />
                </>
              )}

              {recommendationPhone?.display && (
                <>
                  <label
                    className={styles.label}
                    htmlFor={getId("recommendationPhone")}
                  >
                    {recommendationPhone.text}
                  </label>
                  <input
                    id={getId("recommendationPhone")}
                    name="recommendationPhone"
                    type="tel"
                    pattern="^[+0-9\s-]{9,18}$"
                    placeholder={recommendationPhone.placeholder}
                    required={recommendationPhone.required}
                    className={styles.input}
                  />
                </>
              )}

              {additionalInfo?.display && (
                <>
                  <label
                    className={styles.label}
                    htmlFor={getId("additionalInfo")}
                  >
                    {additionalInfo.text}
                  </label>
                  <input
                    id={getId("additionalInfo")}
                    name="additionalInfo"
                    type="text"
                    placeholder={additionalInfo.placeholder}
                    required={additionalInfo.required}
                    className={styles.input}
                  />
                </>
              )}

              {discountCode?.display && (
                <>
                  <label
                    className={styles.label}
                    htmlFor={getId("discountCode")}
                  >
                    {discountCode.text}
                  </label>
                  <input
                    id={getId("discountCode")}
                    name="discountCode"
                    type="text"
                    placeholder={discountCode.placeholder}
                    required={discountCode.required}
                    className={styles.input}
                  />
                </>
              )}

              <div className={styles.checkboxWrapper}>
                <input
                  id={getId("agreement")}
                  name="agreement"
                  type="checkbox"
                  required={agreement.required}
                  className={styles.checkbox}
                />
                <label
                  htmlFor={getId("agreement")}
                  className={styles.checkboxText}
                >
                  {agreement.text}
                </label>
              </div>

              <p className={styles.info}>
                Klikając "{ctaText}" oświadczasz, że zapoznałeś się z
                informacjami na temat przetwarzania danych osobowych, które
                znajdują się&nbsp;
                <a
                  className={styles.infoLink}
                  href={PolitykaPrywatnosci}
                  target="_blank"
                >
                  tutaj
                </a>
                .
              </p>

              <Button
                className={styles.button}
                id={getId("submit-button")}
                type="submit"
                disabled={status === STATUS.PENDING}
              >
                {ctaText}
              </Button>
            </form>
          </>
        )}

        {status === STATUS.SUCCESS && (
          <div>
            <p className={styles.message}>{success}</p>
            <img id="img-success" className={styles.thumb} src={Thumb} />
          </div>
        )}
        {status === STATUS.ERROR && <p className={styles.message}>{error}</p>}
      </div>
    </>
  );
};

export default Form;
