// extracted by mini-css-extract-plugin
export var button = "FormShort-module--button--3gqtM";
export var checkbox = "FormShort-module--checkbox--2DHBx";
export var checkboxText = "FormShort-module--checkboxText--2ZlbK";
export var checkboxWrapper = "FormShort-module--checkboxWrapper--3anfP";
export var fieldsWrapper = "FormShort-module--fieldsWrapper--3bJx2";
export var info = "FormShort-module--info--1jtQm";
export var infoLink = "FormShort-module--infoLink--6DRZf";
export var input = "FormShort-module--input--2qPtl";
export var label = "FormShort-module--label--1GMbz";
export var message = "FormShort-module--message--7ck0C";
export var self = "FormShort-module--self--1hjV6";
export var thumb = "FormShort-module--thumb--3emTR";