import React from "react";

import clsx from "../../utils/clsx";
import * as styles from "./List.module.scss";
import Check from "../../images/check-2.svg";
import { parseMardown } from "../../utils/textTransformations";
import Image from "../Image";

const List = ({ items, className }) => {
  return (
    <ul className={clsx(styles.self, className)}>
      {items.map((item) => {
        return (
          <li key={item.text} className={styles.item}>
            {item.icon ? (
              <Image className={styles.bullet} image={item.icon} />
            ) : (
              <img src={Check} className={styles.bullet} />
            )}
            {parseMardown(item.text)}
          </li>
        );
      })}
    </ul>
  );
};

export default List;
