// extracted by mini-css-extract-plugin
export var arrow = "SideCarousel-module--arrow--1uDoy";
export var arrowNext = "SideCarousel-module--arrowNext--jh9ya";
export var arrowPrev = "SideCarousel-module--arrowPrev--2pjLa";
export var carousel = "SideCarousel-module--carousel--8OLc-";
export var content = "SideCarousel-module--content--XYS6P";
export var desktop = "SideCarousel-module--desktop--1bEgw";
export var desktopWrapper = "SideCarousel-module--desktopWrapper--1PhDR";
export var onlyMobile = "SideCarousel-module--onlyMobile--1xIuE";
export var self = "SideCarousel-module--self--3WdR6";
export var tablet = "SideCarousel-module--tablet--1q2dW";