// extracted by mini-css-extract-plugin
export var bullet = "ApplicationSteps-module--bullet--2HE9M";
export var card = "ApplicationSteps-module--card--26cnn";
export var cardDescription = "ApplicationSteps-module--cardDescription--3GGpR";
export var cardImage = "ApplicationSteps-module--cardImage--AkQj-";
export var cardImageWrapper = "ApplicationSteps-module--cardImageWrapper--2m2XL";
export var cardStep = "ApplicationSteps-module--cardStep--3Iuvq";
export var cardText = "ApplicationSteps-module--cardText--BByT1";
export var cardTitle = "ApplicationSteps-module--cardTitle--12UO3";
export var cards = "ApplicationSteps-module--cards--1b5ON";
export var self = "ApplicationSteps-module--self--2wyOd";
export var title = "ApplicationSteps-module--title--2WoHx";