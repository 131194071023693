// extracted by mini-css-extract-plugin
export var button = "Form-module--button--1e79t";
export var checkbox = "Form-module--checkbox--EmBvj";
export var checkboxText = "Form-module--checkboxText--Y5HNA";
export var checkboxWrapper = "Form-module--checkboxWrapper--tXRQz";
export var info = "Form-module--info--3Lkf9";
export var infoLink = "Form-module--infoLink--UVZL2";
export var input = "Form-module--input--3W4Z9";
export var label = "Form-module--label--h3y8v";
export var message = "Form-module--message--1L9fg";
export var self = "Form-module--self--34jmR";
export var title = "Form-module--title--28XAZ";