import React from "react";

import Hero from "../../sections/Hero";
import ImageWithText from "../../sections/ImageWithText";
import ImageWithTextList from "../../sections/ImageWithTextList";
import OpinionsCarousel from "../../sections/OpinionsCarousel";
import ImageTitleTextList from "../../sections/ImageTitleTextList";
import ApplicationSteps from "../../sections/ApplicationSteps";
import AdvertSection from "../../sections/AdvertSection";
import FormSection from "../../sections/FormSection";
import LinkingModule from "../../sections/LinkingModule";
// import PricingCarousel from "../../sections/PricingCarousel";
import CoursesList from "../../sections/CoursesList";
import YoutubeSection from "../../sections/YoutubeSection";
import Image from "../../sections/Image";
import LearningPlan from "../../sections/LearningPlan";
import Highlights from "../../sections/Highlights";
import CoursesCarousel from "../../sections/CoursesCarousel";
import ImagesCarousel from "../../sections/ImagesCarousel";
import CoursesGrid from "../../sections/CoursesGrid";
import ShortFormSection from "../../sections/ShortFormSection";
import Anchor from "../../sections/Anchor";
import VideosCarousel from "../../sections/VideosCarousel";
import ImagesHorizontalCarousel from "../../sections/ImagesHorizontalCarousel";

import * as styles from "./Section.module.scss";

const getSection = (section, form) => {
  switch (section.strapi_component) {
    case Hero.displayName:
      return <Hero data={section} form={form} />;
    case ImageWithText.displayName:
      return <ImageWithText data={section} />;
    case ImageWithTextList.displayName:
      return <ImageWithTextList data={section} />;
    case OpinionsCarousel.displayName:
      return <OpinionsCarousel data={section} />;
    case ImageTitleTextList.displayName:
      return <ImageTitleTextList data={section} />;
    case ApplicationSteps.displayName:
      return <ApplicationSteps data={section} />;
    case AdvertSection.displayName:
      return <AdvertSection data={section} />;
    case FormSection.displayName:
      return <FormSection data={section} form={form} />;
    case LinkingModule.displayName:
      return <LinkingModule data={section} />;
    // case PricingCarousel.displayName:
    //   return <PricingCarousel data={section} />;
    case CoursesList.displayName:
      return <CoursesList data={section} />;
    case YoutubeSection.displayName:
      return <YoutubeSection data={section} />;
    case Image.displayName:
      return <Image data={section} />;
    case LearningPlan.displayName:
      return <LearningPlan data={section} />;
    case Highlights.displayName:
      return <Highlights data={section} />;
    case CoursesCarousel.displayName:
      return <CoursesCarousel data={section} />;
    case ImagesCarousel.displayName:
      return <ImagesCarousel data={section} />;
    case CoursesGrid.displayName:
      return <CoursesGrid data={section} />;
    case ShortFormSection.displayName:
      return <ShortFormSection data={section} form={form} />;
    case Anchor.displayName:
      return <Anchor data={section} />;
    case VideosCarousel.displayName:
      return <VideosCarousel data={section} />;
    case ImagesHorizontalCarousel.displayName:
      return <ImagesHorizontalCarousel data={section} />;
    default:
      return null;
  }
};

const Section = ({ section, form }) => {
  return <section className={styles.self}>{getSection(section, form)}</section>;
};

export default Section;
