import React from "react";

import * as styles from "./Hero.module.scss";
import Form from "../../components/Form";
import Button from "../../components/Button";
import List from "../../components/List";
import Image from "../../components/Image";
import clsx from "../../utils/clsx";
import GoogleLogo from "../../images/google-logo.svg";
import GoogleStars from "../../images/google-stars.svg";

const Hero = ({ data, form }) => {
  return (
    <div style={{ backgroundColor: data.color }}>
      <div
        className={clsx(styles.self, data.withoutForm && styles.withoutForm)}
      >
        <div
          className={styles.nextSectionTopbar}
          style={{ backgroundColor: data.nextSectionColor || "white" }}
        />
        <div className={styles.contentWrapper}>
          <div
            className={styles.desktopBackground}
            style={{ backgroundImage: `url(${data.image.url})` }}
          />
          <div className={styles.description}>
            {data.heading && <p className={styles.heading}>{data.heading}</p>}
            {data.title && <h1 className={styles.title}>{data.title}</h1>}
            {data.features && data.features.length ? (
              <List className={styles.list} items={data.features} />
            ) : null}
            {data.mobileCtaText && (
              <Button
                color="blue"
                className={styles.mobileButton}
                href="#formularz"
              >
                {data.mobileCtaText}
              </Button>
            )}
            {data.showGoogleBadge && data.googleStarsImage && (
              <div className={styles.ratingContainer}>
                <div className={styles.ratingWrapper}>
                  <div className={styles.rating}>{data.googleRating}</div>
                  <Image
                    className={styles.ratingStars}
                    image={data.googleStarsImage}
                  />
                </div>
                <div className={styles.ratingDescriptionWrapper}>
                  <div className={styles.ratingDescription}>ocena na</div>
                  <img
                    className={styles.ratingGoogleLogo}
                    src={GoogleLogo}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
          <Image className={styles.imageMobile} image={data.mobileImage} />

          {!data.withoutForm && (
            <Form
              className={styles.form}
              form={form}
              formId="form-hero"
              id="formularz"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Hero.displayName = "sections.hero";

export default Hero;
