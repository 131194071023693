import React from "react";
import { Link } from "gatsby";

import * as styles from "./LinkingModule.module.scss";
import Image from "../../components/Image";

const Item = ({ title, landing, image }) => {
  return (
    <Link className={styles.item} to={`/kurs/${landing.slug}`}>
      <Image className={styles.itemImage} image={image} convertToJpg />
      <div className={styles.itemTitle}>{title}</div>
    </Link>
  );
};

const LinkingModule = ({ data: { title, items } }) => {
  return (
    <div className={styles.self}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.items}>{items.map(Item)}</div>
      {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
    </div>
  );
};

LinkingModule.displayName = "sections.linking-module";

export default LinkingModule;
