import React from "react";
import { Link } from "gatsby";
import * as styles from "./CourseCard.module.scss";
import { parseMardown } from "../../utils/textTransformations";
import clsx from "../../utils/clsx";

import Heart from "../../images/heart.svg";
import Star from "../../images/star.svg";

const CourseCard = ({ card, noPadding }) => {
  const {
    title,
    header,
    featuresList,
    image,
    badgeText,
    textInCircle,
    landing,
    bottomText,
  } = card;

  let imageUrl = image?.formats?.small?.url || image.url;
  imageUrl = imageUrl.replace(/\.png$/, ".jpg");

  const Wrapper = landing && landing.slug ? Link : "div";
  const wrapperProps =
    landing && landing.slug
      ? {
          to: `/kurs/${landing.slug}`,
        }
      : {};

  return (
    <div className={clsx(styles.self, noPadding && styles.noPadding)}>
      <Wrapper
        {...wrapperProps}
        className={clsx(
          styles.card,
          landing && landing.slug && styles.linkWrapper
        )}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className={styles.transparentContainer}>
          <div className={styles.badgeContainer}>
            {badgeText ? (
              <div className={styles.badge}>{badgeText}</div>
            ) : (
              <img src={Star} alt="" />
            )}
          </div>
          <span className={styles.header}>{header}</span>
          {featuresList && !!featuresList.length && (
            <ul className={styles.features}>
              {featuresList.map(({ text }) => {
                return (
                  <li className={styles.feature} key={text}>
                    <div className={styles.bulletWrapper}>
                      <img src={Heart} alt="" />
                    </div>
                    {parseMardown(text)}
                  </li>
                );
              })}
            </ul>
          )}
          {textInCircle && (
            <div className={styles.textInCircle}>{textInCircle}</div>
          )}
        </div>
        <div
          className={clsx(styles.content, bottomText && styles.bottomTextAdded)}
        >
          <h3 className={styles.title}>{title}</h3>
          {bottomText && <div className={styles.bottomText}>{bottomText}</div>}
        </div>
      </Wrapper>
    </div>
  );
};

export default CourseCard;
