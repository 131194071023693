import React from "react";

import * as styles from "./Highlights.module.scss";

import { parseMardown } from "../../utils/textTransformations";
import Image from "../../components/Image";

const Highlights = ({ data: { HighlightsItem, title, description } }) => {
  return (
    <div className={styles.self}>
      {(title || description) && (
        <div className={styles.textContent}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {description && <p className={styles.description}>{description}</p>}
        </div>
      )}
      <div className={styles.items}>
        {HighlightsItem.map((item, idx) => (
          <div className={styles.item} key={idx}>
            <div className={styles.iconWrapper}>
              <Image className={styles.icon} image={item.icon} />
            </div>
            <div className={styles.itemDescription}>
              {parseMardown(item.description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Highlights.displayName = "sections.highlights";

export default Highlights;
