import React from "react";

import * as styles from "./CoursesGrid.module.scss";
import IdPointer from "../../components/IdPointer";
import CourseCard from "../../components/CourseCard";
import Button from "../../components/Button";

const CoursesCarousel = ({
  data: { title, buttonText, buttonPath, cards },
}) => {
  return (
    <>
      <IdPointer id="lista-kursow" />
      <div className={styles.self}>
        {title && <h2 className={styles.title}>{title}</h2>}

        <div className={styles.cards}>
          {cards.map((card) => (
            <CourseCard card={card} key={card.title} noPadding />
          ))}
        </div>

        {buttonText && buttonPath && (
          <Button className={styles.button} href={buttonPath} color="blue">
            {buttonText}
          </Button>
        )}
      </div>
    </>
  );
};

CoursesCarousel.displayName = "sections.courses-grid";

export default CoursesCarousel;
