import React from "react";

import * as styles from "./ImagesCarousel.module.scss";
import SideCarousel from "../../components/SideCarousel";
import IdPointer from "../../components/IdPointer";
import DrawingCard from "../../components/DrawingCard";
import { Link } from "gatsby";

const Content = ({ title, subtitle, subtitlePath }) => {
  return (
    <div className={styles.sidebarInner}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && (
        <Link className={styles.subtitle} to={subtitlePath}>
          {subtitle}
        </Link>
      )}
    </div>
  );
};

const ImagesCarousel = ({
  data: { title, linkText, linkPath, ImageCarouselCard: cards },
}) => {
  return (
    <>
      <IdPointer id="prace-uczniow" />
      <div className={styles.self}>
        <SideCarousel
          content={
            <Content
              title={title}
              subtitle={linkText}
              subtitlePath={linkPath}
            />
          }
        >
          {cards.map((card) => (
            <DrawingCard card={card} key={card.title} />
          ))}
        </SideCarousel>
      </div>
    </>
  );
};

ImagesCarousel.displayName = "sections.images-carousel";

export default ImagesCarousel;
