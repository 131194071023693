import React from "react";

import * as styles from "./FormSection.module.scss";
import Form from "../../components/Form";
import IdPointer from "../../components/IdPointer";

const FormSection = ({ data: { title }, form }) => {
  return (
    <>
      <IdPointer id="formularz-kontaktowy" />
      <div className={styles.self}>
        <div className={styles.inner}>
          <h2 className={styles.title}>{title}</h2>
          <Form className={styles.form} form={form} formId="form-section" />
        </div>
      </div>
    </>
  );
};

FormSection.displayName = "sections.form-section";

export default FormSection;
