// extracted by mini-css-extract-plugin
export var centeredTitle = "ImageWithText-module--centeredTitle--8vW71";
export var content = "ImageWithText-module--content--2n3Ue";
export var description = "ImageWithText-module--description--1Z5fp";
export var firstP = "ImageWithText-module--firstP--3OsJf";
export var imageDesktop = "ImageWithText-module--imageDesktop--1z_Zj";
export var imageMobile = "ImageWithText-module--imageMobile--1FvBe";
export var inner = "ImageWithText-module--inner--cBm1J";
export var reversed = "ImageWithText-module--reversed--2ny5Q";
export var secondP = "ImageWithText-module--secondP--3VxvE";
export var self = "ImageWithText-module--self--3L0H3";
export var title = "ImageWithText-module--title--38mc4";