import React from "react";
import { Link } from "gatsby";

import * as styles from "./ApplicationSteps.module.scss";
import Image from "../../components/Image";
import Check from "../../images/check.svg";
import { parseMardown } from "../../utils/textTransformations";

const Step = ({ title, description, image, formLink }, idx) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardImageWrapper}>
        <Image
          className={styles.cardImage}
          image={image}
          type="small"
          convertToJpg
        />
      </div>
      <div className={styles.cardDescription}>
        <div className={styles.cardStep}>
          <img src={Check} className={styles.bullet} />
          {`Krok ${idx + 1}`}
        </div>
        <div className={styles.cardTitle}>
          {formLink ? <Link to="#formularz">{title}</Link> : title}
        </div>
        <p className={styles.cardText}>{parseMardown(description)}</p>
      </div>
    </div>
  );
};

const ApplicationSteps = ({ data: { title, steps } }) => {
  return (
    <div className={styles.self}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.cards}>{steps.map(Step)}</div>
    </div>
  );
};

ApplicationSteps.displayName = "sections.application-steps";

export default ApplicationSteps;
