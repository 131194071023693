import React, { useState } from "react";

import * as styles from "./LearningPlan.module.scss";

import { parseMardown } from "../../utils/textTransformations";
import clsx from "../../utils/clsx";
import IdPointer from "../../components/IdPointer";

const LearningPlan = ({ data: { title, description, AccordionList } }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedAccordionItem, setSelectedAccordionItem] = useState(null);

  return (
    <>
      <IdPointer id="plan-kursu" />
      <div className={styles.self}>
        <div className={styles.content}>
          <h2 className={styles.title}>{parseMardown(title)}</h2>
          <p className={styles.description}>{parseMardown(description)}</p>
          {AccordionList && AccordionList.length > 1 && (
            <ul className={styles.tilesWrapper}>
              {AccordionList.map((item, idx) => (
                <li
                  className={clsx(
                    styles.tile,
                    idx === selectedItem && styles.active
                  )}
                  onClick={() => {
                    setSelectedItem(idx);
                    setSelectedAccordionItem(null);
                  }}
                  key={item.tileTitle}
                >
                  {item.tileTitle}
                </li>
              ))}
            </ul>
          )}
        </div>
        <ol className={styles.accordion}>
          {AccordionList[selectedItem].AccordionListEntry.map((entry, idx) => (
            <li
              className={clsx(
                styles.accordionItem,
                selectedAccordionItem === idx && styles.active
              )}
              onClick={() => {
                if (selectedAccordionItem === idx) {
                  setSelectedAccordionItem(null);
                } else {
                  setSelectedAccordionItem(idx);
                }
              }}
              key={entry.title}
            >
              <div className={styles.accordionItemIndex}>{idx + 1}</div>
              <div className={styles.accordionItemContent}>
                <div className={styles.accordionItemTitleRow}>
                  <div className={styles.accordionItemTitle}>{entry.title}</div>
                  <div className={styles.accordionItemSign}>
                    {selectedAccordionItem === idx ? "-" : "+"}
                  </div>
                </div>
                <div className={styles.accordionItemTextWrapper}>
                  <div className={styles.accordionItemText}>{entry.text}</div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

LearningPlan.displayName = "sections.learning-plan";

export default LearningPlan;
