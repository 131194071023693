import React from "react";
import Slider from "react-slick";

import * as styles from "./Carousel.module.scss";
import "../../assets/css/slick.min.css";
import "../../assets/css/slick-theme.min.css";
import Arrow from "../../images/arrow.svg";

function SampleArrow(props) {
  const { onClick, direction } = props;
  return (
    <div
      className={`${styles.arrow} ${
        direction === "next" ? styles.arrowNext : styles.arrowPrev
      }`}
      onClick={onClick}
    >
      <img src={Arrow} alt="" />
    </div>
  );
}

const Carousel = ({ children, withoutDots }) => {
  const settings = {
    dots: !withoutDots,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
  };

  const mobileSettings = {
    ...settings,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };

  const desktopSettings = {
    ...settings,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleArrow direction="next" />,
    prevArrow: <SampleArrow direction="prev" />,
  };

  return (
    <>
      <div className={styles.onlyMobile}>
        <Slider {...mobileSettings}>{children}</Slider>
      </div>
      <div className={styles.desktop}>
        <Slider {...desktopSettings}>{children}</Slider>
      </div>
    </>
  );
};

export default Carousel;
