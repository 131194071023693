import React from "react";

import Stars from "../../images/gwiazdki.svg";

import * as styles from "./OpinionsCarousel.module.scss";
import Carousel from "../../components/Carousel";
import { parseMardown } from "../../utils/textTransformations";

const Opinion = ({ client, text }) => {
  return (
    <div className={styles.cardWrapper} key={client}>
      <div className={styles.card}>
        <img className={styles.stars} src={Stars} />
        <div className={styles.client}>{client}</div>
        <p className={styles.testimonial}>{parseMardown(text)}</p>
      </div>
    </div>
  );
};

const OpinionsCarousel = ({ data: { title, opinions } }) => {
  return (
    <div className={styles.self}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.carousel}>
        <Carousel>{opinions.map(Opinion)}</Carousel>
      </div>
    </div>
  );
};

OpinionsCarousel.displayName = "sections.opinions-carousel";

export default OpinionsCarousel;
