// extracted by mini-css-extract-plugin
export var content = "YoutubeSection-module--content--2Tfac";
export var description = "YoutubeSection-module--description--2kma3";
export var iconWrapper = "YoutubeSection-module--iconWrapper--2QYZZ";
export var inner = "YoutubeSection-module--inner--2bN28";
export var item = "YoutubeSection-module--item--3YqvU";
export var itemText = "YoutubeSection-module--itemText--3_TW2";
export var list = "YoutubeSection-module--list--3lBrs";
export var mobile = "YoutubeSection-module--mobile--3vPcf";
export var self = "YoutubeSection-module--self--1CLH1";
export var title = "YoutubeSection-module--title--3EQev";
export var videoInner = "YoutubeSection-module--videoInner--3DdNC";
export var videoWrapper = "YoutubeSection-module--videoWrapper--1SBjE";