import React from "react";
import { Link } from "gatsby";

import * as styles from "./Button.module.scss";
import clsx from "../../utils/clsx";

const Button = ({
  children,
  className,
  disabled,
  type = "button",
  href,
  color = "default",
  ...props
}) => {
  const classNames = clsx(
    styles.self,
    className,
    disabled && styles.disabled,
    color && styles[color]
  );

  return href ? (
    <Link to={href} className={classNames} {...props}>
      {children}
    </Link>
  ) : (
    <button className={classNames} disabled={disabled} {...props}>
      {children}
    </button>
  );
};

export default Button;
