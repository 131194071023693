import React from "react";

import * as styles from "./ImageWithText.module.scss";

import { parseMardown } from "../../utils/textTransformations";
import Image from "../../components/Image";
import clsx from "../../utils/clsx";
import IdPointer from "../../components/IdPointer";

const ImageWithText = ({
  data: {
    title,
    firstParagraph,
    secondParagraph,
    image,
    reversed,
    centeredTitle,
  },
}) => {
  return (
    <>
      <IdPointer id={centeredTitle ? "o-nas" : "opis"} />
      <div className={styles.self}>
        <div className={styles.inner}>
          {centeredTitle && (
            <h2 className={clsx(styles.title, styles.centeredTitle)}>
              {title}
            </h2>
          )}
          <div className={clsx(styles.content, reversed && styles.reversed)}>
            <div className={styles.description}>
              {!centeredTitle && (
                <h2 className={styles.title}>{parseMardown(title)}</h2>
              )}
              <p className={styles.firstP}>{parseMardown(firstParagraph)}</p>
              <Image
                className={styles.imageMobile}
                image={image}
                type="small"
              />
              <p className={styles.secondP}>{parseMardown(secondParagraph)}</p>
            </div>
            <Image className={styles.imageDesktop} image={image} type="small" />
          </div>
        </div>
      </div>
    </>
  );
};

ImageWithText.displayName = "sections.image-with-text";

export default ImageWithText;
