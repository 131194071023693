import React from "react";
import { Link } from "gatsby";

import * as styles from "./Image.module.scss";
import ImageComponent from "../../components/Image";
import clsx from "../../utils/clsx";

const Image = ({
  data: { title, imageMobile, imageDesktop, link, isLinkExternal },
}) => {
  const content = (
    <div className={styles.imageWrapper}>
      {imageMobile && (
        <ImageComponent className={styles.imageMobile} image={imageMobile} />
      )}
      {imageDesktop && (
        <ImageComponent
          className={clsx(
            styles.imageDesktop,
            !imageMobile && styles.imageMobile
          )}
          image={imageDesktop}
        />
      )}
    </div>
  );

  return (
    <div className={styles.self}>
      <div className={styles.inner}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {link ? (
          <Link
            to={isLinkExternal ? `${link}` : `/${link}`}
            target={isLinkExternal ? "_blank" : undefined}
            rel={isLinkExternal ? "nofollow" : undefined}
          >
            {content}
          </Link>
        ) : (
          content
        )}
      </div>
    </div>
  );
};

Image.displayName = "sections.image";

export default Image;
