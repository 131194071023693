// extracted by mini-css-extract-plugin
export var button = "AdvertSection-module--button--2T2dh";
export var content = "AdvertSection-module--content--9Rynm";
export var description = "AdvertSection-module--description--y2B9b";
export var greyBackground = "AdvertSection-module--greyBackground--YG3oB";
export var iconWrapper = "AdvertSection-module--iconWrapper--2iW-h";
export var image = "AdvertSection-module--image--1t2tJ";
export var imageWrapper = "AdvertSection-module--imageWrapper--33FqU";
export var inner = "AdvertSection-module--inner--11SiR";
export var item = "AdvertSection-module--item--oGgRk";
export var list = "AdvertSection-module--list--1UYSC";
export var reversed = "AdvertSection-module--reversed--MsOvQ";
export var self = "AdvertSection-module--self--35xWw";
export var tick = "AdvertSection-module--tick--6R_ZQ";
export var title = "AdvertSection-module--title--1nrbP";