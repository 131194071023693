// extracted by mini-css-extract-plugin
export var button = "CoursesList-module--button--17KPx";
export var buttonWrapper = "CoursesList-module--buttonWrapper--27_rQ";
export var card = "CoursesList-module--card--1gAQY";
export var cardWrapper = "CoursesList-module--cardWrapper--168Pv";
export var carousel = "CoursesList-module--carousel--2yAsX";
export var description = "CoursesList-module--description--GDaaR";
export var feature = "CoursesList-module--feature--3o58K";
export var featureTitle = "CoursesList-module--featureTitle--DoIRE";
export var features = "CoursesList-module--features--1uKI1";
export var image = "CoursesList-module--image--3b0jH";
export var imageWrapper = "CoursesList-module--imageWrapper--2Wpfk";
export var label = "CoursesList-module--label--2HmOH";
export var self = "CoursesList-module--self--1B40n";
export var tick = "CoursesList-module--tick--3uXCK";
export var title = "CoursesList-module--title--1Mlmp";