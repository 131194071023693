// extracted by mini-css-extract-plugin
export var button = "ImageTitleTextList-module--button--9Ic8Z";
export var greyBg = "ImageTitleTextList-module--greyBg--3J70K";
export var image = "ImageTitleTextList-module--image--1ZEB3";
export var inner = "ImageTitleTextList-module--inner--zKCFm";
export var item = "ImageTitleTextList-module--item--2KHvl";
export var itemText = "ImageTitleTextList-module--itemText--2M75w";
export var itemTitle = "ImageTitleTextList-module--itemTitle--7-MuV";
export var items = "ImageTitleTextList-module--items--319fS";
export var itemsWrapper = "ImageTitleTextList-module--itemsWrapper--nFcGF";
export var self = "ImageTitleTextList-module--self--Ka8-k";
export var title = "ImageTitleTextList-module--title--17HS0";