import React, { useState } from "react";

import * as styles from "./VideosCarousel.module.scss";
import Carousel from "../../components/Carousel";

const YtVideo = ({ videoId }) => {
  return (
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

const VimeoVideo = ({ videoId }) => {
  return (
    <iframe
      src={`https://player.vimeo.com/video/${videoId}?h=53d2edaede&autoplay=1`}
      width="640"
      height="360"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  );
};

const VideoModal = ({ videoId, setVideoToWatch }) => {
  const [platform, id] = videoId.split(":");

  return (
    <div className={styles.modal}>
      <div
        className={styles.modalBackground}
        onClick={() => setVideoToWatch(null)}
      />
      <div className={styles.videoContainer}>
        {platform === "yt" ? (
          <YtVideo videoId={id} />
        ) : (
          <VimeoVideo videoId={id} />
        )}
      </div>
    </div>
  );
};

const VideoCard = ({ id, image, youtubeId, vimeoId, setVideoToWatch }) => {
  const videoId = youtubeId ? `yt:${youtubeId}` : `vi:${vimeoId}`;
  return (
    <div className={styles.cardWrapper} key={id}>
      <div
        className={styles.card}
        style={{ backgroundImage: `url(${image.url})` }}
        onClick={() => setVideoToWatch(videoId)}
      />
    </div>
  );
};

const VideosCarousel = ({ data: { title, videoCards } }) => {
  const [videoToWatch, setVideoToWatch] = useState(null);

  return (
    <div className={styles.self}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.carousel}>
        <Carousel withoutDots>
          {videoCards.map((video) => (
            <VideoCard setVideoToWatch={setVideoToWatch} {...video} />
          ))}
        </Carousel>
      </div>
      {videoToWatch && (
        <VideoModal videoId={videoToWatch} setVideoToWatch={setVideoToWatch} />
      )}
    </div>
  );
};

VideosCarousel.displayName = "sections.videos-carousel";

export default VideosCarousel;
