import React from "react";

import * as styles from "./YoutubeSection.module.scss";
import { parseMardown } from "../../utils/textTransformations";
import Image from "../../components/Image";
import clsx from "../../utils/clsx";

const Item = ({ text, icon }) => {
  return (
    <li className={styles.item} key={text}>
      {icon && (
        <span className={styles.iconWrapper}>
          <Image className={styles.icon} image={icon} />
        </span>
      )}
      <span className={styles.itemText}>{parseMardown(text)}</span>
    </li>
  );
};

const Video = ({ videoId, mobile }) => {
  return (
    <div className={clsx(styles.videoWrapper, mobile && styles.mobile)}>
      <div className={styles.videoInner}>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

const YoutubeSection = ({ data: { title, text, list, videoId } }) => {
  return (
    <div className={styles.self}>
      <div className={styles.inner}>
        <div className={styles.content}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {text && <p className={styles.description}>{parseMardown(text)}</p>}
          <Video videoId={videoId} mobile />
          {!!list.length && <ul className={styles.list}>{list.map(Item)}</ul>}
        </div>
        <Video videoId={videoId} />
      </div>
    </div>
  );
};

YoutubeSection.displayName = "sections.youtube-section";

export default YoutubeSection;
