import React from "react";
import { Link } from "gatsby";

import * as styles from "./ImagesHorizontalCarousel.module.scss";
import Carousel from "../../components/Carousel";
import ImageComponent from "../../components/Image";
import { parseMardown } from "../../utils/textTransformations";

const ImageCard = ({ title, description, image, externalLink }) => {
  const WrapperComponent = externalLink ? Link : "div";

  return (
    <div className={styles.cardWrapper} key={title}>
      <WrapperComponent
        className={styles.card}
        to={externalLink ? externalLink : undefined}
        target={externalLink ? "_blank" : undefined}
        rel={externalLink ? "nofollow" : undefined}
      >
        <ImageComponent image={image} className={styles.image} />
        <div className={styles.content}>
          <div className={styles.cardTitle}>{title}</div>
          <p className={styles.description}>{parseMardown(description)}</p>
        </div>
      </WrapperComponent>
    </div>
  );
};

const ImagesHorizontalCarousel = ({ data: { title, imageCards } }) => {
  return (
    <div className={styles.self}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.carousel}>
        <Carousel withoutDots>{imageCards.map(ImageCard)}</Carousel>
      </div>
    </div>
  );
};

ImagesHorizontalCarousel.displayName = "sections.images-horizontal-carousel";

export default ImagesHorizontalCarousel;
